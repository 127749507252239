/* global GLOBAL_CONFIG */
import { CMSDialog, CMSDialogOptions, useDialogStore, useSocketConnected, useSockets } from '@editor/ui-utils';
import type { Socket } from 'socket.io-client';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

interface IServerToClientEventData {
  serverVersion: string;
  forceUpdate: boolean;
  softUpdate: boolean;
}

interface IServerToClientEvents {
  handshakeReply: (handshake: IServerToClientEventData) => void;
}

interface IClientToServerEvents {
  handshake: (clientVersion: string) => void;
}

enum UpdateDialogType {
  FORCE_UPDATE = 'FORCE_UPDATE',
  SOFT_UPDATE = 'SOFT_UPDATE',
}

export default function usePing(): void {
  let lastNotifiedVersion: string;
  let hasForceUpdateTakenOver = false;

  const { t } = useI18n();
  const { showDialog, updateDialog, isDialogVisible } = useDialogStore();
  const forceReloadDialogOptions: CMSDialogOptions = {
    message: t('pingApi.forceUpdate'),
    ok: t('pingApi.reload'),
    cancel: false,
    persistent: true,
  };
  const forcedReloadDialog = new CMSDialog(forceReloadDialogOptions, UpdateDialogType.FORCE_UPDATE);
  const softReloadDialog = new CMSDialog(
    {
      message: t('pingApi.softUpdate'),
      ok: t('pingApi.reload'),
      cancel: t('pingApi.softUpdateCancel'),
      persistent: false,
    },
    UpdateDialogType.SOFT_UPDATE,
  );

  const handleSocketHandshakeReply = async ({ serverVersion, forceUpdate, softUpdate }: IServerToClientEventData): Promise<void> => {
    // Exit early if there's no action required (force update dialog already visible)
    if (hasForceUpdateTakenOver) return;

    // Exit early if there's no action required (versions match and no force update)
    if (lastNotifiedVersion === serverVersion && !forceUpdate) return;

    // if force update dialog is open, there is no need for any other action than force user to reload
    const isForceUpdateDialogOpen = isDialogVisible(UpdateDialogType.FORCE_UPDATE);
    if (isForceUpdateDialogOpen) return;

    // if soft update dialog is open and another message with soft update came from server there is also nothing to do
    const isSoftUpdateDialogOpen = isDialogVisible(UpdateDialogType.SOFT_UPDATE);
    if (isSoftUpdateDialogOpen && softUpdate) return;

    if (forceUpdate) {
      hasForceUpdateTakenOver = true; // mark as forced to update, either by updated soft update dialog or new force update dialog

      // if force update message came while soft update dialog is open, dialog window has to be updated and attached with reload on dismiss
      if (isSoftUpdateDialogOpen) {
        updateDialog(new CMSDialog(forceReloadDialogOptions, UpdateDialogType.SOFT_UPDATE)).onDismiss(() => window.location.reload());
        return;
      }

      showDialog(forcedReloadDialog).onDismiss(() => window.location.reload());
      return;
    }

    if (softUpdate) {
      showDialog(softReloadDialog)
        .onOk(() => window.location.reload())
        .onCancel(() => (lastNotifiedVersion = serverVersion));
    }
  };

  const handleSocketResponse = (isConnected?: boolean): void => {
    if (!isConnected) return;
    const io = useSockets();
    const pingChannel: Socket<IServerToClientEvents, IClientToServerEvents> = io.socket('/ping');
    pingChannel.on('handshakeReply', handleSocketHandshakeReply);
    pingChannel.on('connect', () => {
      pingChannel.emit('handshake', GLOBAL_CONFIG.VERSION);
    });
  };

  watch(useSocketConnected(), handleSocketResponse, { immediate: true });
}
